<!-- @format -->
<script lang="ts">
  import { getStoresContext, type StoresKey } from "~/stores";
  import { getStores } from "../utils";
  import type { ManageAPI } from "~/manage-api/types";
  import { 
    ANY_DAY, 
    ANY_STORE, 
    ANY_TAG, 
    ANY_DESCRIPTION,
    filter_by_category, 
    filter_by_day, 
    filter_by_store, 
    filter_by_offer_content 
  } from "../store";
  
  export let request_store_key: StoresKey;

  const stores_context = getStoresContext(request_store_key);
  const { T, offers_ext } = stores_context;

  $: ({ items } = $offers_ext);

  let value = "";
  let storesSuggest: Pick<ManageAPI.Store, 'id' | 'name'>[] = [];
  let offersSuggest: any[] = [];
  
  function action(type: string, id: number) {
    if (type == 'store') {
      const preStoredStores: number[] = $filter_by_store as number[];
      const newStores: number[] = preStoredStores.includes(id) ? [...preStoredStores] : [...preStoredStores, id];
      filter_by_store.set(newStores);
      filter_by_offer_content.set([]);
    } else if (type === 'offer') {
      const preStoredOffers: number[] = $filter_by_offer_content as number[];
      const newOffers: number[] = preStoredOffers.includes(id) ? [...preStoredOffers] : [...preStoredOffers, id];
      filter_by_offer_content.set(newOffers);
      filter_by_store.set([]);
    }
    value = "";
    storesSuggest = [];
    offersSuggest = [];
  }

  function filterByStore (e: Event) {
    const input = e.currentTarget instanceof HTMLInputElement ? e.currentTarget.value : '';
    if (input) {
      if (input.length >= 2) {
        const stores = items && getStores(items);
        $filter_by_day = ANY_DAY;
        $filter_by_category = ANY_TAG;
        storesSuggest = stores.filter(
          (store) =>
            store.name.toLowerCase().indexOf(input.toLowerCase()) > -1,
        );
        offersSuggest = items.filter(
          (offer) =>
            offer.store.name.toLowerCase().indexOf(input.toLowerCase()) > -1 || 
            offer.title.toLowerCase().indexOf(input.toLowerCase()) > -1 || 
            offer.description1.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
            offer.description2.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
            offer.longDescription.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
            offer.tags.find(tag => tag.name.toLowerCase().indexOf(input.toLowerCase()) > -1) ||
            (offer?.lowestPriceInXDays ?? '').toLowerCase().indexOf(input.toLowerCase()) > -1
        );
      }
    } else {
      $filter_by_store = ANY_STORE;
      $filter_by_offer_content = ANY_DESCRIPTION;
      storesSuggest = [];
      offersSuggest = [];
    }
  }

  $: t = $T;
</script>

<div class="search-container mb-3 pr-3 pl-3">
  {#if items && items.length}
    <div class="search-wrapper">
      <div class="input-group p-1">
        <input
          on:input={filterByStore}
          bind:value
          type="text"
          placeholder={t`offers.searchBox`}
          class="search-box form-control bg-none border-0" 
        />
        <div class="input-group-append border-0">
          <button id="button-addon3" type="button" class="btn btn-link"
            ><i class="fa fa-search"></i></button>
        </div>
      </div>
      {#if storesSuggest.length || offersSuggest.length}
        <div class="search-dropdown show">
          {#each storesSuggest as store}
            <button
              class="stores__suggestion dropdown-item"
              on:click={() => {
                action('store', store.id);
              }}>
              <span class="stores-suggestion-type">{t`offers.store`}</span>{store.name}
            </button>
          {/each}
          {#each offersSuggest as offer}
            <button
              class="stores__suggestion dropdown-item"
              on:click={() => {
                action('offer', offer.id);
              }}>
              <span class="stores-suggestion-type">{t`offers.offer`}</span>{offer.title || offer.description1 || offer.description2}
            </button>
          {/each}
        </div>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .search-container {
    font-family: 'KHGrotesk', sans-serif;
  }
  button {
    border: none;
  }
  .input-group-append i {
    color: var(--brandColor1);
    font-size: 18px;
    padding-top: 5px;
  }
  .search-wrapper {
    position: relative;
    margin: 0 auto;
    min-width: 200px;
    max-width: 700px;
    width: 100%;
    border-color: var(--brandColor1);
    box-shadow:
      0 0 2px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px 0 rgba(0, 0, 0, 0.22);

    .input-group {
      background: #fff;
      border: 1px solid var(--brandColor1);
    }

    .search-box {
      background: #fff;
      height: 42px;
      font-size: 16px;
      color: var(--brandColor1) !important;
      line-height: 100%;
      letter-spacing: 0%;
    }::placeholder {
      color: var(--brandColor1);
    }

    .form-control:focus {
      border: none;
      box-shadow: none;
    }

    button {
      background: #fff;
    }

    .search-dropdown {
      position: absolute;
      top: 55px;
      width: 100%;
      border: 1px solid var(--brandColor1);
      z-index: 9;
    }

    .dropdown-item {
      cursor: pointer;
      border-bottom: 1px solid var(--brandColor1);
      text-align: left;
      font-size: 14px;
      overflow-x: hidden;
      .stores-suggestion-type {
        background-color: var(--brandColor1);
        color: #fff;
        font-size: 11px;
        margin-right: 7px;
        margin-left: -5px;
        padding: 2px 5px;
        min-width: 50px;
        display: inline-block;
        text-align: center;
      }
      &:hover {
        background-color: #c6c6c6;
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .search-wrapper {
      width: 92vw;
      margin-top: -5px;
    }
  }
</style>
